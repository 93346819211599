import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Layout,
  Flex,
  Avatar,
  Menu,
  Image,
  Space,
  Drawer,
  Button,
  Typography,
  Spin,
} from "antd";
import { logout } from "../../store/authSlice";
import { LogoutOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import { getUserProjects } from "../../store/projectSlice";
import TopnavUser from "./TopnavUser";
import "./Layout.css";

const { Header } = Layout;

const getIsMobile = () => window.innerWidth <= 768;

export default function Topnav({ currentProject }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(getIsMobile());
  const user = useSelector((state) => state.auth.user);
  const [recentProjects, setRecentProjects] = useState([]);
  const [projectItems, setProjectItems] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const handleLogout = () => {
    dispatch(logout());
  };

  const onProjectChange = () => {
    setLoading(true);
    dispatch(getUserProjects({ page: 1, pageSize: 5 })).then((response) => {
      if (response.type === "project/get-user-projects/fulfilled") {
        setRecentProjects(response.payload.results);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    let recentItems = [];
    if (loading) {
      recentItems = [
        {
          label: <Spin size="small" />,
          key: "loading",
        },
      ];
    } else {
      if (recentProjects.length > 0) {
        recentItems = recentProjects.map((project) => {
          return {
            label: (
              <Link to={`/project/${project.slug}/`}>
                {project.slug} {project.name}
              </Link>
            ),
            key: `${project.slug}`,
            disabled: project.id === currentProject?.id,
          };
        });
      } else {
        recentItems = [
          {
            label: "No Recent Projects",
            key: "empty",
            disabled: true,
          },
        ];
      }
    }

    setProjectItems([
      {
        label: "Recent Projects",
        type: "group",
        key: "recents",
        children: recentItems,
      },
      {
        label: "Others",
        key: "others",
        type: "group",
        children: [
          {
            label: <Link to={"/all-projects/"}>View All Projects</Link>,
            key: "all-projects",
          },
          {
            label: <Link to={"/new-project/"}>Add New Project</Link>,
            key: "add-projects",
          },
        ],
      },
    ]);
  }, [recentProjects, loading, currentProject]);

  const openDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const items = user
    ? [
        {
          label: (
            <Button onClick={onProjectChange}>
              <Space>
                <Typography.Text ellipsis={true}>
                  {currentProject ? `${currentProject.slug}` : "Select Project"}
                </Typography.Text>
                {!isMobile && <DownOutlined />}
              </Space>
            </Button>
          ),
          key: "project",
          children: projectItems,
        },
      ]
    : null;

  if (user && isMobile) {
    items.push({
      label: <Link to={"/update-profile/"}>Update Profile</Link>,
      key: "update-profile",
      icon: <EditOutlined size={35} />,
    });
    items.push({
      label: <Link onClick={handleLogout}>Logout</Link>,
      key: "logout",
      icon: <LogoutOutlined size={35} />,
    });
  } else if (user && !isMobile) {
    items.push({
      label: <TopnavUser user={user} />,
      key: "profile",
      children: [
        {
          label: <Link to={"/update-profile/"}>Update Profile</Link>,
          key: "update-profile",
          icon: <EditOutlined size={35} />,
        },
        {
          label: <Link onClick={handleLogout}>Logout</Link>,
          key: "logout",
          icon: <LogoutOutlined size={35} />,
        },
      ],
    });
  }

  return (
    <Header className="topnav-header">
      <Flex>
        <Image
          src={`${process.env.PUBLIC_URL}/static/logo_new.png`}
          alt="logo"
          preview={false}
          style={{
            width: "auto",
            height: isMobile ? "60px" : "75px",
            objectFit: "contain",
          }}
        />
      </Flex>
      {user && (
        <>
          {isMobile && (
            <>
              <Button onClick={openDrawer} type="text">
                <TopnavUser user={user} />
              </Button>
              <Drawer
                title="Menu"
                placement="right"
                open={open}
                onClose={onClose}
              >
                <Menu
                  theme="light"
                  items={items}
                  selectable={false}
                  mode="inline"
                  onClick={onClose}
                />
              </Drawer>
            </>
          )}
          {!isMobile && (
            <>
              <Menu
                theme="light"
                mode="horizontal"
                items={items}
                triggerSubMenuAction={"click"}
                selectable={false}
                style={{
                  paddingRight: 20,
                }}
                expandIcon={<DownOutlined />}
              />
            </>
          )}
        </>
      )}
    </Header>
  );
}
