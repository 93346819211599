import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";
import { message } from "antd";

// User Timecard Actions
export const getProjectDailyTime = createAsyncThunk(
  "project-daily-time/get-member-timecard",
  async ({ project, qparams }, thunkAPI) => {
    try {
      const res = await api.get(
        `timecard/timecard/project/${project}/${qparams}`
      );
      if (res.status === 200) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

// // Add new time
// export const addMemberDailyTime = createAsyncThunk(
//   "project-daily-time/add-project-daily-time",
//   async ({ data, memberID }, thunkAPI) => {
//     try {
//       const res = await api.post(
//         `timecard/timecard/member/${memberID}/`,
//         data
//       );

//       if (res.status === 201) {
//         return res.data;
//       } else {
//         return thunkAPI.rejectWithValue(res.data);
//       }
//     } catch (err) {
//       return thunkAPI.rejectWithValue(err.response.data);
//     }
//   }
// );

// export const updateMemberDailyTime = createAsyncThunk(
//   "project-daily-time/update-project-daily-time",
//   async (data, thunkAPI) => {
//     try {
//       const res = await api.put(
//         `timecard/timecard/member/${data.member}/${data.id}/`,
//         data
//       );
//       if (res.status === 200) {
//         return res.data;
//       } else {
//         return thunkAPI.rejectWithValue(res.data);
//       }
//     } catch (err) {
//       return thunkAPI.rejectWithValue(err.response.data);
//     }
//   }
// );

// export const deleteMemberDailyTime = createAsyncThunk(
//   "project-daily-time/delete-project-daily-time",
//   async ({ memberID, id }, thunkAPI) => {
//     try {
//       const res = await api.delete(
//         `timecard/timecard/member/${memberID}/${id}/`
//       );
//       if (res.status === 204) {
//         return res.data;
//       } else {
//         return thunkAPI.rejectWithValue(res.data);
//       }
//     } catch (err) {
//       return thunkAPI.rejectWithValue(err.response.data);
//     }
//   }
// );

const initialState = {
  loading: false,
  error: null,
  projectDailyTime: [],
};

const projectDailyTimeSlice = createSlice({
  name: "project-daily-time",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProjectDailyTime.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectDailyTime.fulfilled, (state, action) => {
        state.loading = false;
        state.projectDailyTime = action.payload;
      })
      .addCase(getProjectDailyTime.rejected, (state) => {
        state.loading = false;
        state.projectDailyTime = [];
      });
  },
});

export default projectDailyTimeSlice;
