import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";

export const getMontlyMemberSummary = createAsyncThunk(
  "summaries/get-montly-member-summary",
  async ({ projectID, memberID, monthStart, monthEnd }, thunkAPI) => {
    try {
      const res = await api.get(
        `timecard/timecard/member/monthly-summary/${projectID}/${memberID}/?month_start=${monthStart}&month_end=${monthEnd}`
      );
      if (res.status === 200) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  loading: false,
  monthlyMemberSummary: [],
};

const timeSummariesSlice = createSlice({
  name: "summaries",
  initialState: initialState,
  reducers: {
    resetSummaries: (state) => {
      state.loading = false;
      state.monthlyMemberSummary = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMontlyMemberSummary.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMontlyMemberSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.monthlyMemberSummary = action.payload;
      })
      .addCase(getMontlyMemberSummary.rejected, (state) => {
        state.loading = false;
        state.monthlyMemberSummary = [];
      });
  },
});

export default timeSummariesSlice;
