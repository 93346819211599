import React from "react";
import { Space, Avatar, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";

export default function TopnavUser({ user }) {
  if (user) {
    return (
      <Space>
        <Avatar
          size={30}
          style={{ verticalAlign: "middle" }}
          icon={
            user.photo ? null : (
              <UserOutlined style={{ alignItems: "center" }} />
            )
          }
          src={user.photo ? user.photo : null}
        />
        <Typography.Link>{user.first_name}</Typography.Link>
      </Space>
    );
  }
  return null;
}
