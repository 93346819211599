import React, { useState, useEffect, useRef } from "react";
import { Layout } from "antd";
import Topnav from "./Topnav";
import { Outlet } from "react-router-dom";
import "./Layout.css";
import { useNavigate, useOutletContext, useLocation } from "react-router-dom";

export default function AppLayout() {
  const navigate = useNavigate();
  const { user } = useOutletContext();
  const [currentProject, setCurrentProject] = useState(null);
  const didMountRef = useRef(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (didMountRef.current === false) {
      if (user && pathname === "/") {
        didMountRef.current = true;
        if (!user.recent_project) {
          navigate("/all-projects/");
        } else {
          navigate(`/project/${user.recent_project}/`);
        }
      }
    }
  }, [user, navigate, pathname]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Topnav currentProject={currentProject} />
      <Layout>
        <Outlet
          context={{ user: user, setCurrentProject: setCurrentProject }}
        />
      </Layout>
    </Layout>
  );
}
