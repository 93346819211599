import React from "react";
import { useOutletContext, Navigate } from "react-router-dom";
import { Result, Button } from "antd";

const AdminRoute = ({ requiredPermission, children }) => {
  const { memberPermission } = useOutletContext(); // Access context from ProjectLayout

  if (memberPermission !== requiredPermission) {
    // Render 403 page for unauthorized users
    return (
      <Result
        status="403"
        title="403 Forbidden"
        subTitle="You do not have permission to access this page."
        extra={
          <Button type="primary" onClick={() => window.history.back()}>
            Go Back
          </Button>
        }
      />
    );
  }

  // Render the child element if the user has the required permission
  return children;
};

export default AdminRoute;
