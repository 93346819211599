import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";
import fileDownload from "js-file-download";

export const downloadProjectTimecard = createAsyncThunk(
  "timecard/download",
  async ({ id, params }, thunkAPI) => {
    try {
      const res = await api.get(`timecard/download/${id}/`, {
        params,
        responseType: "blob",
      });
      if (res.status === 200) {
        fileDownload(res.data, "work_hours_report.xlsx");
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

// Get Project Timecard
export const getProjectTimecard = createAsyncThunk(
  "timecard/get",
  async ({ id, params }, thunkAPI) => {
    try {
      const res = await api.get(`/timecard/approved-timecard/${id}/`, {
        params,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

// Approve Timecard
export const approveProjectTimecard = createAsyncThunk(
  "timecard/create",
  async (data, thunkAPI) => {
    try {
      const res = await api.post(
        `/timecard/approved-timecard/${data.project}/`,
        data
      );
      if (res.status === 201) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  loading: false,
  hasError: false,
};

const timecardSlice = createSlice({
  name: "timecard",
  initialState: initialState,
  reducers: {
    resetUserProjects: (state) => {
      state.loadingUserProjects = false;
      state.userProjects = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadProjectTimecard.pending, (state) => {
        state.loading = true;
        state.hasError = false;
      })
      .addCase(downloadProjectTimecard.fulfilled, (state, action) => {
        state.loading = false;
        state.hasError = false;
      })
      .addCase(downloadProjectTimecard.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      });
  },
});

export default timecardSlice;
