import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";

export const loadHolidays = createAsyncThunk(
  "holiday/list",
  async (data, thunkAPI) => {
    try {
      const res = await api.get(
        `timecard/holiday/${data.project_id}/${data.qparams}`
      );
      if (res.status === 200) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createHoliday = createAsyncThunk(
  "holiday/create",
  async (data, thunkAPI) => {
    try {
      const res = await api.post(`/timecard/holiday/${data.project}/`, data);
      if (res.status === 201) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateHoliday = createAsyncThunk(
  "holiday/update",
  async (data, thunkAPI) => {
    try {
      const res = await api.put(
        `timecard/holiday/${data.project}/${data.id}/`,
        data
      );
      if (res.status === 200) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteHoliday = createAsyncThunk(
  "holiday/delete",
  async (data, thunkAPI) => {
    try {
      const res = await api.delete(
        `timecard/holiday/${data.project_id}/${data.id}/`
      );
      if (res.status === 204) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  error: null,
  holidays: [],
  holidayDetails: null,
  isLoading: false,
};

const holidaySlice = createSlice({
  name: "holiday",
  initialState: initialState,
  reducers: {
    resetHolidays: (state) => {
      state.isLoading = false;
      state.holidays = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadHolidays.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loadHolidays.fulfilled, (state, action) => {
        state.isLoading = false;
        state.holidays = action.payload;
      })
      .addCase(loadHolidays.rejected, (state) => {
        state.isLoading = false;
        state.holidays = [];
      })

      .addCase(createHoliday.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createHoliday.fulfilled, (state, action) => {
        state.isLoading = false;
        state.holidayDetails = action.payload;
      })
      .addCase(createHoliday.rejected, (state) => {
        state.isLoading = false;
        state.holidayDetails = null;
      })

      .addCase(updateHoliday.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateHoliday.fulfilled, (state, action) => {
        state.isLoading = false;
        state.holidayDetails = action.payload;
      })
      .addCase(updateHoliday.rejected, (state) => {
        state.isLoading = false;
        state.holidayDetails = null;
      })

      .addCase(deleteHoliday.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteHoliday.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteHoliday.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default holidaySlice;
