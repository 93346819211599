import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";
import { message } from "antd";

// User Timecard Actions
export const getMemberDailyTime = createAsyncThunk(
  "member-daily-time/get-member-timecard",
  async ({ memberID, startDate, endDate }, thunkAPI) => {
    try {
      const res = await api.get(
        `timecard/timecard/member/${memberID}/?start_date=${startDate}&end_date=${endDate}`
      );
      if (res.status === 200) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

// Add new time
export const addMemberDailyTime = createAsyncThunk(
  "member-daily-time/add-member-daily-time",
  async ({ data, memberID }, thunkAPI) => {
    try {
      const res = await api.post(`timecard/timecard/member/${memberID}/`, data);

      if (res.status === 201) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateMemberDailyTime = createAsyncThunk(
  "member-daily-time/update-member-daily-time",
  async (data, thunkAPI) => {
    try {
      const res = await api.put(
        `timecard/timecard/member/${data.member}/${data.id}/`,
        data
      );
      if (res.status === 200) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteMemberDailyTime = createAsyncThunk(
  "member-daily-time/delete-member-daily-time",
  async ({ memberID, id }, thunkAPI) => {
    try {
      const res = await api.delete(
        `timecard/timecard/member/${memberID}/${id}/`
      );
      if (res.status === 204) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  loading: false,
  memberDailyTime: [],
  details: null,
  error: null,
};

const memberDailyTimeSlice = createSlice({
  name: "member-daily-time",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMemberDailyTime.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMemberDailyTime.fulfilled, (state, action) => {
        state.loading = false;
        state.memberDailyTime = action.payload;
      })
      .addCase(getMemberDailyTime.rejected, (state) => {
        state.loading = false;
        state.memberDailyTime = null;
      })
      .addCase(addMemberDailyTime.pending, (state) => {
        state.loading = true;
      })
      .addCase(addMemberDailyTime.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
      })
      .addCase(addMemberDailyTime.rejected, (state) => {
        state.loading = false;
        state.details = null;
      })
      .addCase(updateMemberDailyTime.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMemberDailyTime.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
      })
      .addCase(updateMemberDailyTime.rejected, (state) => {
        state.loading = false;
        state.details = null;
      })
      .addCase(deleteMemberDailyTime.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteMemberDailyTime.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteMemberDailyTime.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default memberDailyTimeSlice;
