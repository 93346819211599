import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import api from "../api";

// Create a common action for resetting all slices
export const resetAll = createAsyncThunk("reset/all", async (thunkAPI) => {
  return null;
});

export const login = createAsyncThunk("auth/login", async (user, thunkAPI) => {
  return user;
});

export const serverLogin = createAsyncThunk(
  "auth/server-login",
  async (body, thunkAPI) => {
    try {
      message.loading({
        key: "login-action",
        content: "Logging In",
      });

      const res = await api.post("auth/login/regular/", body);
      if (res.status === 200) {
        message.success({
          key: "login-action",
          content: "Logging Success",
        });
        return res.data;
      } else {
        message.error({
          key: "login-action",
          content: "Something went wrong",
        });
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      if (err.response.status === 400) {
        message.error({
          key: "login-action",
          content: "Incorrect Credentials",
        });
      } else {
        message.error({
          key: "login-action",
          content: "Something went wrong",
        });
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const loadUser = createAsyncThunk("auth/user", async (_, thunkAPI) => {
  try {
    const res = await api.get("auth/user/");
    if (res.status === 200) {
      return res.data;
    } else {
      return thunkAPI.rejectWithValue(res.data);
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const logout = createAsyncThunk("auth/logout", async (thunkAPI) => {
  setTimeout(() => {
    return null;
  }, 2000);
});

// User Search API
export const searchUser = createAsyncThunk(
  "auth/search-user",
  async (qparams, thunkAPI) => {
    try {
      const res = await api.get(`auth/user/search/${qparams}`);
      if (res.status === 200) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const sendOTP = createAsyncThunk(
  "auth/send-otp",
  async ({ email }, thunkAPI) => {
    try {
      const data = {
        email,
      };
      const res = await api.post("auth/generate-otp/", data);
      if (res.status === 200) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const verifyOTP = createAsyncThunk(
  "auth/verify-otp",
  async ({ email, otp }, thunkAPI) => {
    try {
      const res = await api.post("auth/verify-otp/", { email, otp });
      if (res.status === 200) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "auth/update-profile",
  async (data, thunkAPI) => {
    try {
      const res = await api.put("auth/update-profile/", data);
      if (res.status === 200) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  isAuthenticated: false,
  error: null,
  user: null,
  isLoading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    unauthUser: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoading = false;
        state.isAuthenticated = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(loadUser.pending, (state) => {
        state.isLoading = true;
        state.isAuthenticated = false;
        state.user = null;
      })
      .addCase(loadUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoading = false;
        state.isAuthenticated = true;
      })
      .addCase(loadUser.rejected, (state) => {
        state.isLoading = false;
        state.isAuthenticated = false;
        state.user = null;
      })
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(logout.fulfilled, (state) => {
        localStorage.clear();
        return initialState;
      })
      .addCase(logout.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(resetAll.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetAll.fulfilled, (state) => {
        localStorage.clear();
        return initialState;
      });
  },
});

export const { unauthUser } = authSlice.actions;
export default authSlice;
