import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import projectSlice from "./projectSlice";
import memberDailyTimeSlice from "./memberDailyTimeSlice";
import projectDailyTimeSlice from "./projectDailyTimeSlice";
import holidaySlice from "./holidaySlice";
import locationSlice from "./locationSlice";
import timecardSlice from "./timecardSlice";
import timeSummariesSlice from "./timeSummariesSlice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    project: projectSlice.reducer,
    holiday: holidaySlice.reducer,
    location: locationSlice.reducer,
    projectDailyTime: projectDailyTimeSlice.reducer,
    memberDailyTime: memberDailyTimeSlice.reducer,
    timecard: timecardSlice.reducer,
    summaries: timeSummariesSlice.reducer,
  },
});

export default store;
