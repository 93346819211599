import React from "react";
import AppRoutes from "./AppRoutes";
import { ConfigProvider } from "antd";
import { MessageProvider } from "./components/MessageProvider/MessageProvider";
import dayjs from "dayjs";
import "dayjs/locale/en";
import updateLocale from "dayjs/plugin/updateLocale";
import locale from "antd/es/locale/en_US";

dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1,
});

function App() {
  return (
    <ConfigProvider locale={locale}>
      <MessageProvider>
        <AppRoutes />
      </MessageProvider>
    </ConfigProvider>
  );
}

export default App;
