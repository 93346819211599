import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Layout } from "antd";
import Sidenav from "./Sidenav";
import {
  Outlet,
  useParams,
  useOutletContext,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { getProjectDetails } from "../../store/projectSlice";
import PageLoader from "../../components/PageLoader";
import "./Layout.css";

const { Content } = Layout;

export default function ProjectLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams();
  const { user, setCurrentProject } = useOutletContext();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [collapsedWidth, setCollapsedWidth] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [project, setProject] = useState(null);
  const [memberPermission, setMemberPermission] = useState(null);

  useEffect(() => {
    setLoading(true);
    dispatch(getProjectDetails(slug)).then((response) => {
      if (response.type === "project/details/fulfilled") {
        setProject(response.payload);
        setCurrentProject({
          id: response.payload.id,
          slug: response.payload.slug,
          name: response.payload.name,
        });
        setLoading(false);
      } else if (response.type === "project/details/rejected") {
        navigate("/page403/");
      }
    });
  }, [slug]);

  useEffect(() => {
    if (project) {
      const member_details = project.auth_member_details;
      const currentPath = location.pathname;
      const projectSlugPattern = /^\/project\/[^/]+\/?$/;
      const isTopProjURL = projectSlugPattern.test(currentPath);

      if (member_details) {
        if (
          (member_details.access_type === "admin" ||
            member_details.access_type === "member") &&
          member_details.member_permission === "admin" &&
          member_details.is_external
        ) {
          setMemberPermission("admin");
          if (isTopProjURL) {
            navigate("project-timecard/");
          }
        } else if (
          member_details.access_type === "member" &&
          member_details.member_permission === "admin" &&
          !member_details.is_external
        ) {
          setMemberPermission("admin");
          if (isTopProjURL) {
            navigate(`user-timecard/${member_details?.id}/`);
          }
        } else {
          setMemberPermission("regular");
          if (isTopProjURL) {
            navigate(`user-timecard/${member_details?.id}/`);
          }
        }
      } else {
        setMemberPermission(null);
        navigate("/page403/");
      }
    }
  }, [project]);

  if (loading) {
    return <PageLoader message={"Retrieving project details"} />;
  } else {
    return (
      <>
        <Sidenav
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          collapsedWidth={collapsedWidth}
          setCollapsedWidth={setCollapsedWidth}
          project={project}
          memberPermission={memberPermission}
        />
        <Content
          className="layout-content"
          style={{
            marginLeft: collapsedWidth === 80 ? (collapsed ? 90 : 260) : 15,
            transition: "margin-left 0.2s",
          }}
        >
          <Outlet context={{ user, project, memberPermission }} />
        </Content>
      </>
    );
  }
}
