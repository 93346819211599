import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";

export const getProjectLocations = createAsyncThunk(
  "location/get",
  async ({ id }, thunkAPI) => {
    try {
      const res = await api.get(`timecard/location/${id}/`);
      if (res.status === 200) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const addProjectLocation = createAsyncThunk(
  "location/add",
  async ({ projectId, data }, thunkAPI) => {
    try {
      const res = await api.post(`timecard/location/${projectId}/`, data);
      if (res.status === 201) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateProjectLocation = createAsyncThunk(
  "location/update",
  async ({ projectId, id, data }, thunkAPI) => {
    try {
      const res = await api.put(`timecard/location/${projectId}/${id}/`, data);
      if (res.status === 200) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteProjectLocation = createAsyncThunk(
  "location/delete",
  async ({ projectId, id }, thunkAPI) => {
    try {
      const res = await api.delete(`timecard/location/${projectId}/${id}/`);
      if (res.status === 200) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  error: null,
  locations: [],
  locationDetails: null,
  isLoading: false,
};

const locationSlice = createSlice({
  name: "location",
  initialState: initialState,
  reducers: {
    resetLocations: (state) => {
      state.isLoading = false;
      state.locations = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectLocations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProjectLocations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.locations = action.payload;
      })
      .addCase(getProjectLocations.rejected, (state) => {
        state.isLoading = false;
        state.locations = [];
      })

      .addCase(addProjectLocation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addProjectLocation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.locationDetails = action.payload;
      })
      .addCase(addProjectLocation.rejected, (state) => {
        state.isLoading = false;
        state.locationDetails = null;
      })

      .addCase(updateProjectLocation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProjectLocation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.locationDetails = action.payload;
      })
      .addCase(updateProjectLocation.rejected, (state) => {
        state.isLoading = false;
        state.locationDetails = null;
      })

      .addCase(deleteProjectLocation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProjectLocation.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteProjectLocation.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default locationSlice;
