import React from "react";
import { Flex, Spin } from "antd";

export default function PageLoader({ message }) {
  return (
    <Flex
      flex={1}
      align={"center"}
      justify={"center"}
      vertical
      style={{
        height: "100%",
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0,
      }}
    >
      <Spin tip={message ? message : "Loading"} size="large">
        <div
          style={{
            padding: 100,
            borderRadius: 4,
          }}
        />
      </Spin>
    </Flex>
  );
}
