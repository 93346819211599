import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loadUser } from "./store/authSlice";
import AdminRoute from "./components/AdminRoute/AdminRoute";
import AppLayout from "./components/Layout/AppLayout";
import ProjectLayout from "./components/Layout/ProjectLayout";
import "./index.css";

// Lazy Imports
const Spinner = lazy(() => import("./components/Spinner"));
const Loader = lazy(() => import("./components/Loader"));
const Page404 = lazy(() => import("./pages/Page404"));
const Page403 = lazy(() => import("./components/Page403/Page403"));
const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./pages/Login"));
const SocialAuth = lazy(() => import("./pages/SocialAuth"));
const UpdateProfile = lazy(() => import("./pages/UpdateProfile/UpdateProfile"));
const CreateProject = lazy(() => import("./pages/CreateProject"));
const ProjectMembers = lazy(() =>
  import("./pages/ProjectMembers/ProjectMembers")
);
const ProjectDailyTime = lazy(() =>
  import("./pages/ProjectDailyTime/ProjectDailyTime")
);
const UserTimecard = lazy(() => import("./pages/UserTimecard/UserTimecard"));
const ProjectSettings = lazy(() => import("./pages/ProjectSettings"));
const Holidays = lazy(() => import("./pages/Holidays/Holidays"));
const ProjectCategories = lazy(() =>
  import("./pages/ProjectSettings/ProjectCategories")
);
const ProjectLocations = lazy(() =>
  import("./pages/ProjectLocations/ProjectLocations")
);

// Private Routes
const PrivateRoute = () => {
  // const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const auth = useSelector((state) => state.auth);
  const location = useLocation();

  useEffect(() => {
    if (auth.isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [auth]);

  if (loading) {
    return <Spinner />;
  } else if (auth.user) {
    return <Outlet context={{ user: auth.user }} />;
  } else {
    return <Navigate to="/login/" replace state={{ from: location }} />;
  }
};

export default function AppRoutes() {
  const didMountRef = useRef(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!didMountRef.current) {
      dispatch(loadUser());
      didMountRef.current = true;
    }
  }, []);

  const router = createBrowserRouter([
    {
      path: "/login",
      element: (
        <Suspense fallback={<Loader />}>
          <Login />
        </Suspense>
      ),
    },
    {
      path: "/google",
      element: <SocialAuth />,
    },
    {
      element: <PrivateRoute />,
      errorElement: <Page404 />,
      children: [
        {
          element: <AppLayout />,
          path: "/",
          children: [
            {
              path: "/update-profile/",
              element: (
                <Suspense fallback={<Loader />}>
                  <UpdateProfile />
                </Suspense>
              ),
            },
            {
              path: "/all-projects/",
              element: (
                <Suspense fallback={<Loader />}>
                  <Home />
                </Suspense>
              ),
            },
            {
              path: "/new-project/",
              element: (
                <Suspense fallback={<Loader />}>
                  <CreateProject />
                </Suspense>
              ),
            },
            {
              path: "/page403/",
              element: (
                <Suspense fallback={<Loader />}>
                  <Page403 />
                </Suspense>
              ),
            },
            {
              path: "/project/:slug/",
              element: <ProjectLayout />,
              children: [
                {
                  path: "/project/:slug/project-timecard/",
                  element: (
                    <AdminRoute requiredPermission={"admin"}>
                      <Suspense fallback={<Loader />}>
                        <ProjectDailyTime />
                      </Suspense>
                    </AdminRoute>
                  ),
                },
                {
                  path: "/project/:slug/user-timecard/:member/",
                  element: (
                    <Suspense fallback={<Loader />}>
                      <UserTimecard />
                    </Suspense>
                  ),
                },
                {
                  path: "/project/:slug/project-members/",
                  element: (
                    <Suspense fallback={<Loader />}>
                      <ProjectMembers />
                    </Suspense>
                  ),
                },
                {
                  path: "/project/:slug/settings/details/",
                  element: (
                    <AdminRoute requiredPermission={"admin"}>
                      <Suspense fallback={<Loader />}>
                        <ProjectSettings />
                      </Suspense>
                    </AdminRoute>
                  ),
                },
                {
                  path: "/project/:slug/settings/categories/",
                  element: (
                    <AdminRoute requiredPermission={"admin"}>
                      <Suspense fallback={<Loader />}>
                        <ProjectCategories />
                      </Suspense>
                    </AdminRoute>
                  ),
                },
                {
                  path: "/project/:slug/holidays/",
                  element: (
                    <Suspense fallback={<Loader />}>
                      <Holidays />
                    </Suspense>
                  ),
                },
                {
                  path: "/project/:slug/settings/locations/",
                  element: (
                    <AdminRoute requiredPermission={"admin"}>
                      <Suspense fallback={<Loader />}>
                        <ProjectLocations />
                      </Suspense>
                    </AdminRoute>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}
