import axios from "axios";
import { message } from "antd";

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8000/api/v1/"
      : "/api/v1/",
  timeout: 5000, // Set your desired timeout
});

// Separate Axios instance for refresh token requests
const refreshApi = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8000/api/v1/"
      : "/api/v1/",
  timeout: 5000,
});

// Flag and subscriber list for handling multiple refresh requests
let isRefreshing = false;
let refreshSubscribers = [];

// Function to add subscribers for token refresh
const subscribeTokenRefresh = (callback) => {
  return new Promise((resolve, reject) => {
    refreshSubscribers.push({ resolve, reject });
  });
};

// Function to notify all subscribers when a token is refreshed
const onRefreshed = (newToken) => {
  refreshSubscribers.forEach((subscriber) => subscriber.resolve(newToken));
  refreshSubscribers = [];
};

// Function to notify all subscribers of failure
const onRefreshFailed = (error) => {
  refreshSubscribers.forEach((subscriber) => subscriber.reject(error));
  refreshSubscribers = [];
};

// Request interceptor
api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Axios interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (!error.response) {
      if (error.request) {
        message.error("No response from server. Please check your connection.");
      } else {
        message.error(`Request error: ${error.message}`);
      }
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const existingRefreshToken = localStorage.getItem("refresh_token");
      if (!existingRefreshToken) {
        localStorage.clear();
        return Promise.reject(new Error("RefreshTokenNotFound"));
      }

      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const res = await refreshApi.post("/auth/token/refresh/", {
            refresh: existingRefreshToken,
          });
          const { access, refresh } = res.data;
          // Update tokens in local storage
          localStorage.setItem("access_token", access);
          localStorage.setItem("refresh_token", refresh);

          // Update Axios default headers
          api.defaults.headers.common["Authorization"] = `Bearer ${access}`;

          // Notify all subscribers with the new token
          onRefreshed(access);
          isRefreshing = false;

          // Retry the original request with the new token
          return api(originalRequest);
        } catch (error) {
          // Clear storage and notify failure if refresh fails
          localStorage.clear();
          onRefreshFailed(error);
          isRefreshing = false;
          return Promise.reject(error);
        }
      }
      // Queue subsequent requests while refresh is ongoing
      return subscribeTokenRefresh((newToken) => {
        originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
        return api(originalRequest);
      });
    }

    return Promise.reject(error);
  }
);

export default api;
