import React, { createContext, useContext } from "react";
import { message } from "antd";

// Create Context
const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <MessageContext.Provider value={messageApi}>
      {contextHolder}
      {children}
    </MessageContext.Provider>
  );
};

// Custom Hook to Use Message
export const useMessage = () => useContext(MessageContext);
