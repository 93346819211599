import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Layout, Menu, Flex, Typography, Button } from "antd";
import { getUserProjects } from "../../store/projectSlice";

import {
  ScheduleOutlined,
  SettingOutlined,
  TeamOutlined,
  SolutionOutlined,
  ApartmentOutlined,
  EnvironmentOutlined,
  CalendarOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";

import "./Layout.css";

const { Sider } = Layout;

const defaultSiderStyle = {
  height: "100vh",
};
const defaultMenuStyle = {
  width: "250px",
  height: "100vh",
};

export default function Sidenav({
  collapsed,
  setCollapsed,
  collapsedWidth,
  setCollapsedWidth,
  project,
  memberPermission,
}) {
  const location = useLocation();
  const [siderStyle, setSiderStyle] = useState(defaultSiderStyle);
  const [menuStyle, setMenuStyle] = useState(defaultMenuStyle);
  const [isMobile, setIsMobile] = useState(false);

  const onBreakpoint = (broken) => {
    setIsMobile(broken);
    if (broken) {
      setSiderStyle({
        height: "100vh",
        position: "fixed",
        zIndex: 9,
        transition: "all 0.2s",
      });
      setCollapsedWidth(0);
    } else {
      setSiderStyle({
        height: "100vh",
        position: "fixed",
        zIndex: 9,
        overflow: "auto",
        left: 0,
        transition: "all 0.2s",
      });
      setMenuStyle(null);
      setCollapsedWidth(80);
    }
  };

  const toggleSider = () => {
    setCollapsed(!collapsed); // Toggle the Sider
  };

  let timecardItems = [
    {
      label: (
        <Link to={`/project/${project.slug}/project-timecard/`}>
          Project Timecard
        </Link>
      ),
      key: `/project/${project.slug}/project-timecard/`,
      icon: <ScheduleOutlined />,
    },
    {
      label: (
        <Link
          to={`/project/${project.slug}/user-timecard/${project?.auth_member_details?.id}/`}
        >
          My Timecard
        </Link>
      ),
      key: `/project/${project.slug}/user-timecard/${project?.auth_member_details?.id}/`,
      icon: <SolutionOutlined />,
    },
  ];

  if (project?.auth_member_details.is_external) {
    timecardItems = timecardItems.filter(
      (obj) =>
        obj.key !==
        `/project/${project.slug}/user-timecard/${project?.auth_member_details?.id}/`
    );
  } else {
    if (project.auth_member_details.member_permission === "regular") {
      timecardItems = timecardItems.filter(
        (obj) => obj.key !== `/project/${project.slug}/project-timecard/`
      );
    }
  }

  const othersChildren = [
    {
      label: <Link to={`/project/${project.slug}/holidays/`}>Holidays</Link>,
      key: `/project/${project.slug}/holidays/`,
      icon: <CalendarOutlined />,
    },
  ];

  if (memberPermission === "admin") {
    othersChildren.push({
      label: (
        <Link to={`/project/${project.slug}/settings/locations/`}>
          Locations
        </Link>
      ),
      key: `/project/${project.slug}/settings/locations/`,
      icon: <EnvironmentOutlined />,
    });
    othersChildren.push({
      label: (
        <Link to={`/project/${project.slug}/settings/categories/`}>
          Categories
        </Link>
      ),
      key: `/project/${project.slug}/settings/categories/`,
      icon: <ApartmentOutlined />,
    });
  }

  let items = [
    {
      label: "Timecard",
      key: "timecard",
      type: "group",
      children: timecardItems,
    },
    {
      label: "Members",
      key: "members",
      type: "group",
      children: [
        {
          label: (
            <Link to={`/project/${project.slug}/project-members/`}>
              Project Members
            </Link>
          ),
          key: `/project/${project.slug}/project-members/`,
          icon: <TeamOutlined />,
        },
      ],
    },
    {
      label: "Others",
      key: "others",
      type: "group",
      children: othersChildren,
    },
  ];

  if (project.auth_member_details.member_permission === "admin") {
    items.push({
      label: "Settings",
      key: "settings",
      type: "group",
      children: [
        {
          label: (
            <Link to={`/project/${project.slug}/settings/details/`}>
              Project Settings
            </Link>
          ),
          key: `/project/${project.slug}/settings/details/`,
          icon: <SettingOutlined />,
        },
      ],
    });
  }

  return (
    <>
      {isMobile && (
        <Button
          type="text"
          icon={<MenuUnfoldOutlined />}
          onClick={toggleSider}
          style={{
            position: "fixed",
            top: 20,
            left: 20,
            zIndex: 1000,
          }}
        />
      )}

      <Sider
        breakpoint="lg"
        collapsedWidth={collapsedWidth}
        width={250}
        theme="light"
        onBreakpoint={onBreakpoint}
        style={siderStyle}
        collapsible={true}
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Menu
          style={menuStyle}
          mode="inline"
          // theme="light"
          items={items}
          // selectable={true}
          selectedKeys={[location.pathname]}
        />
      </Sider>
    </>
  );
}
